import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import ContactForm from '../ContactForm'
import { Container } from '../global'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "mac-mini" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Cloud Hosting</Subtitle>

            <h1>
              Your Mac,
              <br />
              in the cloud.
              <Beta size="badge">Beta</Beta>
            </h1>
            <h2>
              We&apos;ve built the first and best Mac cloud hosting service,
              specifically designed to meet the needs of Australian Banks and
              Financial Services Companies.
              <br />
              <br />
              Sign up to get early access.
            </h2>
            <ContactForm />
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
          <Beta size="sm">
            <b>BETA:</b> This is a new service -{' '}
            <a href="mailto:info@mountain-pass.com.au">your feedback</a> will
            help us to improve it.
          </Beta>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const Beta = styled(({ className = '', size = 'lg', ...props }) => (
  <div className={`${className} beta size-${size}`} {...props} />
))`
  background-color: ${(props) => props.theme.color.accent};
  a {
    color: ${(props) => props.theme.color.primary};
  }
  &.size-badge {
    display: inline-block;
    font-size: 22px !important;
    line-height: 22px !important;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    top: -8px;
    padding: 4px 8px;
  }
  &.size-sm {
    display: block;
    padding: 8px 16px;
  }
`

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  padding: 120px 0 200px 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 80px 0 60px 0;
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
